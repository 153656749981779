// snakeCase("address1") => "address1" (and not "address_1")
function snakeCase(str) {
  return _.snakeCase(str).replace(/_(\d+)$/, "$1");
}

function toSnakeCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((result, key) => {
      const snakeCaseKey = snakeCase(key);
      result[snakeCaseKey] = toSnakeCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
}

function toCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => toCamelCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((result, key) => {
      const newKey = _.camelCase(key);
      result[newKey] = toCamelCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
}

function camelCaseToNormalText(key) {
  let result = key.replace(/([0-9])([A-Za-z])/g, "$1 $2");
  result = result.replace(/([A-Z])/g, " $1");
  result = result
    .toLowerCase()
    .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  return result.trim();
}

function transformHashKeys(hash) {
  const transformedHash = {};

  for (const key in hash) {
    if (hash.hasOwnProperty(key)) {
      const newKey = camelCaseToNormalText(key);
      transformedHash[newKey] = hash[key];
    }
  }

  return transformedHash;
}

export { toSnakeCase, toCamelCase, transformHashKeys };
