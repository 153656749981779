<template>
  <div>
    <PageHeader title="Suivi des onboardings" />

    <SearchBar
      class="mb-6"
      :placeholder="$t('Rechercher un magasin')"
      :current-page="currentPage"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      :initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywords"
    />

    <Tabs :tabs="tabs" @tab-selected="selectTab" />

    <Table
      class="mt-10"
      :columns="columns"
      :data="data"
      :is-accessible="true"
      @view="redirectToStorePage"
      @sort="sortBy"
    >
      <template #progression="{ row }">
        <div class="flex flex-col">
          <span
            :class="[
              progressionClasses(row),
              'inline-block w-min items-center px-3 py-0.5 rounded-full text-xs font-medium',
            ]"
          >
            {{ $t(`store.onboarding.progression_status.${row.progression.status}`) }}
          </span>

          <span
            v-if="row.progression.ro"
            :class="[
              roClasses(row),
              'mt-1 inline-block w-min items-center px-3 py-0.5 rounded-full text-xs font-medium',
            ]"
          >
            {{ Math.round(row.progression.ro * 100) }} %
          </span>
        </div>
      </template>
      <template #statusPhase="{ row }">
        <div class="flex">
          <div class="rounded-lg">
            <h2 class="text-xs text-gray-700">{{ row.statusPhase?.value }}</h2>
            <span
              v-if="row.statusPhase?.actionRequired"
              class="bg-yellow-100 text-yellow-800 inline-block items-center px-3 py-0.5 mt-1 rounded-full text-xs font-medium"
            >
              Une action est requise
            </span>
          </div>
        </div>
      </template>
      <template #date="{ row }">
        <div class="flex">
          <div class="rounded-lg">
            <h2 class="text-xs text-gray-700">{{ row.date }}</h2>
            <span
              v-if="daysRemaining(row) > 0"
              class="bg-purple-100 text-purple-800 inline-block items-center px-3 py-0.5 mt-1 rounded-full text-xs font-medium"
            >
              {{ daysRemaining(row) }} jours restants
            </span>
            <span
              v-if="daysOfDelays(row) > 0"
              class="bg-red-100 text-red-800 inline-block items-center px-3 py-0.5 mt-1 rounded-full text-xs font-medium"
            >
              {{ daysOfDelays(row) }} jours de retard
            </span>
          </div>
        </div>
      </template>
      <template #salesChannel="{ row }">
        <div class="flex">
          <div class="rounded-lg">
            <h2 class="text-xs text-gray-700">{{ row.salesChannel }}</h2>
            <span
              v-if="row.salesFieldManager"
              :class="[
                salesFieldManagerColorClass(row.salesFieldManager),
                'inline-block items-center px-3 py-0.5 mt-1 rounded-full text-xs font-medium',
              ]"
            >
              {{ row.salesFieldManager }}
            </span>
          </div>
        </div>
      </template>
    </Table>

    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import { Table, Tabs, Pagination } from "@estaly/ui";
import SearchBar from "@/components/menu/SearchBar.vue";
import PageHeader from "@/components/utils/PageHeader.vue";
import { humanize, toCamelCase } from "@/utils/utils";
import { isPresent } from "@/utils/validation";

export default {
  components: {
    Pagination,
    PageHeader,
    SearchBar,
    Table,
    Tabs,
  },

  computed: {
    columns() {
      if (!isPresent(this.data)) return [];

      const slotKeys = ["date", "progression", "statusPhase", "salesChannel"];
      const percentageKeys = ["firstRo", "secondRo"];

      return Object.keys(this.data[0])
        .filter((key) => key !== "id" && key !== "salesFieldManager")
        .map((key) => {
          return {
            field: key,
            label: this.$t(`${humanize(key)}`),
            bold: false,
            slot: slotKeys.includes(key) ? key : null,
            percentage: percentageKeys.includes(key),
            sortable: key === "date",
          };
        });
    },
  },
  data() {
    return {
      data: [],
      currentStatus: null,
      tabs: [
        {
          name: "setup",
          current: true,
          displayName: "Setup",
        },
        {
          name: "formation",
          current: false,
          displayName: "Formation",
        },
        {
          name: "rampup",
          current: false,
          displayName: "Rampup",
        },
        {
          name: null,
          current: false,
          displayName: "Tous",
        },
      ],
      currentPage: 1,
      totalCount: null,
      totalPages: null,
      keywords: null,
      sortColumn: null,
      sortOrder: null,
      availableFilters: {
        category: [],
        salesFieldManager: [],
        statusPhase: [],
      },
      selectedFilters: {
        category: [],
        salesFieldManager: [],
        statusPhase: [],
      },
    };
  },

  beforeMount() {
    this.currentStatus = this.tabs[0].name;
    this.getStores();
  },

  methods: {
    async getStores() {
      const params = {
        status: this.currentStatus,
        keywords: this.keywords,
        category: this.selectedFilters.category,
        salesFieldManager: this.selectedFilters.salesFieldManager,
        statusPhase: this.selectedFilters.statusPhase,
        page: this.currentPage,
      };

      const response = await BackofficeService.getOnboardingData(params);

      if (response?.success) {
        const formattedResponse = toCamelCase(response.data);
        this.data = formattedResponse.stores;
        this.totalCount = formattedResponse.page.totalCount;
        this.totalPages = formattedResponse.page.totalPages;
        this.availableFilters = formattedResponse.filters;
      }
    },

    redirectToStorePage(row) {
      window.open(`/stores/${row.id}`, "_blank");
    },

    selectTab(tab) {
      this.currentStatus = tab?.name;
      this.selectedFilters.statusPhase = [];
      this.handleChangePage(1);
    },

    updateFilterSearch(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateKeywords(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },

    handleChangePage(newPage) {
      this.currentPage = newPage;
      this.getStores();
    },

    salesFieldManagerColorClass(salesFieldManager) {
      const salesFieldManagers = [...new Set(this.data.map((row) => row.salesFieldManager).filter(Boolean))];

      const index = salesFieldManagers.indexOf(salesFieldManager);

      const colorClasses = [
        "bg-blue-100 text-blue-800",
        "bg-green-100 text-green-800",
        "bg-red-100 text-red-800",
        "bg-yellow-100 text-yellow-800",
        "bg-purple-100 text-purple-800",
        "bg-pink-100 text-pink-800",
        "bg-teal-100 text-teal-800",
        "bg-indigo-100 text-indigo-800",
        "bg-gray-100 text-gray-800", // Default color
      ];

      return colorClasses[index % colorClasses.length];
    },

    progressionClasses(row) {
      if (row.progression.status === "late") return "bg-red-100 text-red-800";
      if (row.progression.status === "ahead_of_schedule") return "bg-green-100 text-green-800";
      return "bg-blue-100 text-blue-800";
    },

    roClasses(row) {
      if (row.progression.status === "late") return "text-red-800";
      if (row.progression.status === "ahead_of_schedule") return "text-green-800";
      return "text-blue-800";
    },

    daysOfDelays(row) {
      if (!row.progression) return 0;
      return row.progression.daysRemaining < 0 ? Math.abs(row.progression.daysRemaining) : 0;
    },

    daysRemaining(row) {
      if (!row.progression) return 0;
      return row.progression.daysRemaining > 0 ? row.progression.daysRemaining : 0;
    },

    async sortBy(column) {
      // TODO: Redo a fetch for the pagination and the sorting
      // for now, the sorting is limited to the current page
      // https://www.notion.so/estaly/Onboarding-pouvoir-trier-par-date-198a6e3780638060ac35fb59d34f21dd?pvs=4
      if (column.field !== "date") return;
      if (!isPresent(this.data)) return;

      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.data = this.data.sort((a, b) => {
        const aDate = Date.parse(a.date.split("/").reverse().join("/"));
        const bDate = Date.parse(b.date.split("/").reverse().join("/"));
        if (this.sortOrder === "asc") return aDate < bDate ? -1 : 1;
        return bDate < aDate ? -1 : 1;
      });
    },
  },
};
</script>
