<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Sinistres de ce client</h3>
    </div>
    <div class="border-t border-gray-200 bg-white">
      <Loading v-if="loading" />
      <PaginatedClaimList
        v-else
        :total-number-of-claims="totalNumberOfClaims"
        :current-page="currentPage"
        :claims="claims"
        @changePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { Loading } from "@estaly/ui";

export default {
  components: {
    PaginatedClaimList,
    Loading,
  },

  props: ["claim"],

  data() {
    return {
      loading: true,
      claims: [],
      totalNumberOfClaims: 1,
      currentPage: 1,
    };
  },

  async mounted() {
    await this.retrieveClaims();
  },

  methods: {
    async retrieveClaims() {
      this.loading = true;
      const response = await BackofficeService.getClaims({
        page: this.currentPage,
        keywords: this.claim.customerEmail,
      });
      if (response?.success) {
        this.claims = response.data.claims;
        this.totalNumberOfClaims = response.data.totalNumberOfClaims;
      }
      this.loading = false;
    },
    async handleChangePage(newPage) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      await this.retrieveClaims();
    },
  },
};
</script>
