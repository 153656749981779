import BackofficeService from "@/api/services/backoffice";
import { isObjectValid } from "@/utils/validation";

const initialState = {
  currentClaim: null,
  availableFilters: {
    status: [],
    organization_id: [],
    store_id: [],
  },
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    doesClaimExists: (state) => {
      return isObjectValid(state.currentClaim);
    },

    approveRepairQuotation: (state) => {
      return state.currentClaim.requiredAction === "approve_repair_quotation";
    },
  },

  mutations: {
    setCurrentClaim(state, currentClaim) {
      state.currentClaim = currentClaim;
    },
  },

  actions: {
    async getClaim({ commit, dispatch }, claimFriendlyId) {
      dispatch("resetCurrentClaim");
      const response = await BackofficeService.getClaimByFriendlyId(claimFriendlyId);
      if (!response?.success) return response;

      const claim = response.data;
      commit("setCurrentClaim", claim);
      return response;
    },

    async updateClaimStatus({ state, dispatch }, params) {
      const response = await BackofficeService.updateClaimStatus(params);
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimInvoice({ state, dispatch }, { costItems, invoiceFile }) {
      const response = await BackofficeService.uploadClaimInvoice(state.currentClaim, costItems, invoiceFile);
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimQuotation({ state, dispatch }, { costItems, quotationFile, productState }) {
      const response = await BackofficeService.uploadQuotation(
        state.currentClaim,
        costItems,
        quotationFile,
        productState,
      );
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    impossibleClaimQuotationUpload: async function ({ state, dispatch }, { message, reason }) {
      const response = await BackofficeService.impossibleQuotationUpload(state.currentClaim, message, reason);
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionApproveQuotation({ state, dispatch }) {
      const response = await BackofficeService.executeActionOnClaims(
        state.currentClaim.friendlyId,
        "approve_repair_quotation",
      );
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionRefuseQuotation({ state, dispatch }) {
      const response = await BackofficeService.executeActionOnClaims(
        state.currentClaim.friendlyId,
        "refuse_repair_quotation",
      );
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionApproveInvoice({ state, dispatch }) {
      const response = await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "approve_invoice");
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionRefuseInvoice({ state, dispatch }) {
      const response = await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "refuse_invoice");
      if (!response?.success) return response;

      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    resetCurrentClaim({ commit }) {
      commit("setCurrentClaim", null);
    },
  },
};
