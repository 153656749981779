import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import { plainAxiosInstance, securedAxiosInstance } from "./api/axios";
import "./index.css";
import { setupI18n, i18nPluginExtended } from "@estaly/ui/src/utils/i18n.js";
import "flowbite";
import { RollbarPlugin } from "./rollbar";

async function importMessages(locale) {
  return await import(/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`);
}

const i18n = setupI18n({ locale: "fr", importMessages });

const app = createApp(App);

app.use(RollbarPlugin);
app.use(store);
app.use(router);
app.use(i18n);
app.use(i18nPluginExtended);
app.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance,
});
app.mount("#app");
