import { securedAxiosInstance, errorNotification } from "../axios";
import { isObjectValid } from "@/utils/validation";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class BackofficeService {
  getOrganizations(params) {
    return securedAxiosInstance.get("/backoffice/organizations", { params });
  }

  getOrganizationOptions() {
    return securedAxiosInstance.get("/backoffice/organizations/options");
  }

  createOrganization(params) {
    return securedAxiosInstance.post(`/backoffice/organizations`, toSnakeCase(params));
  }

  getOrganization(organizationId) {
    return securedAxiosInstance.get(`/backoffice/organizations/${organizationId}`);
  }

  getDiscounts() {
    return securedAxiosInstance.get("/backoffice/discounts");
  }

  getChallenges(includes = []) {
    return securedAxiosInstance.get("/challenges", {
      params: { includes },
    });
  }

  deleteChallenge(id) {
    return securedAxiosInstance.delete(`/backoffice/challenges/${id}`);
  }

  getChallengesAndSellersPerformance(storeId, challengeId) {
    return securedAxiosInstance.get("challenges/performance", {
      params: {
        store_id: storeId,
        challenge_id: challengeId,
      },
    });
  }

  downloadChallengeReporting(challengeId, storeIds) {
    return securedAxiosInstance.get("challenges/reporting", {
      params: {
        challenge_id: challengeId,
        store_ids: storeIds,
      },
    });
  }

  getStores(params) {
    return securedAxiosInstance.get("/backoffice/stores", { params });
  }

  getStore(storeId) {
    return securedAxiosInstance.get(`/backoffice/stores/${storeId}`);
  }

  updateStoreSalesFieldManager(storeId, salesFieldManagerId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      sales_field_manager_id: salesFieldManagerId,
    });
  }

  getProducts(
    storeId,
    organizationId,
    productTypes,
    vendors,
    offerStatuses,
    approvalStatuses,
    tags,
    categories,
    keywords,
    title,
    source,
    parents,
    variants,
    online,
    offline,
    page,
  ) {
    const data = {
      filtering: {
        product_types: productTypes,
        vendors: vendors,
        offer_statuses: offerStatuses,
        approval_statuses: approvalStatuses,
        tags: tags,
        categories: categories,
      },
      keywords: keywords,
      title: title,
      source: source,
      parents: parents,
      variants: variants,
      online: online,
      offline: offline,
      page: page,
      store_ids: isObjectValid(storeId) ? [storeId] : null,
      organization_ids: isObjectValid(organizationId) ? [organizationId] : null,
    };
    return securedAxiosInstance.post(`/backoffice/products`, data);
  }

  getLeads(page, filteringData) {
    return securedAxiosInstance.get("/backoffice/leads", { params: { page, ...toSnakeCase(filteringData) } });
  }

  getProductById(storeId, productId) {
    return securedAxiosInstance.get(`/backoffice/products/${productId}`);
  }

  createDiscount(discountType, title, offeredMonths, finalPrice) {
    const data = {
      discountType: discountType,
      title: title,
      offeredMonths: offeredMonths,
      finalPrice: finalPrice,
    };
    return securedAxiosInstance.post("/backoffice/discounts", data);
  }

  createChallenge(data) {
    return securedAxiosInstance.post("/backoffice/challenges", { challenge: toSnakeCase(data) });
  }

  executeActionOnProducts(products_id, action_type) {
    const data = {
      products_id: products_id,
      action_type: action_type,
    };
    return securedAxiosInstance.put("/backoffice/products/action", data);
  }

  executeActionOnClaims(claim_id, action_type) {
    const data = {
      claim_id: claim_id,
      action_type: action_type,
    };
    return securedAxiosInstance.put("/backoffice/claims/action", data);
  }

  productPlatformUpdate(enabled, products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      enabled ? "publish_products" : "unpublish_products",
    );
  }

  matchProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "match_products");
  }

  approveMatch(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "approve_and_match");
  }

  approveMatchPublish(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(
      allProductsSelected ? approvedProductsIds : products_id,
      "approve_match_and_publish_products",
    );
  }

  unpublishProducts(products_id, allProductsSelected, approvedProductsIds) {
    this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "unpublish_products");
  }

  unmatchProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "unmatch_products");
  }

  unapproveProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "unapprove_products");
  }

  approveProducts(products_id, allProductsSelected, approvedProductsIds) {
    return this.executeActionOnProducts(allProductsSelected ? approvedProductsIds : products_id, "approve_products");
  }

  updateProductCategory(product, newCategory) {
    const data = {
      product_id: product.id,
      new_category: newCategory,
    };
    return securedAxiosInstance.put("/backoffice/products/category", data);
  }

  getClaims({ page, status, keywords, organizations, stores }) {
    const data = {
      organizations: organizations,
      stores: stores,
      status: status,
      page: page,
      keywords: keywords,
    };

    return securedAxiosInstance.post(`/backoffice/claims`, data);
  }

  getClaimByFriendlyId(friendlyId) {
    return securedAxiosInstance.get(`/claims/${friendlyId}`);
  }

  updateLead(leadId, data) {
    return securedAxiosInstance.put(`/backoffice/leads/${leadId}`, toSnakeCase(data));
  }

  updateStoreDiscount(storeId, discountId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      discount_id: discountId,
    });
  }

  getPlanSales(data) {
    return securedAxiosInstance.post("/backoffice/plan_sales", data);
  }

  getPlanSale(planSaleId) {
    return securedAxiosInstance.get(`/backoffice/plan_sales/${planSaleId}`);
  }

  cancelPlanSale(planSaleId, cancellationReason, feedbackMessage) {
    return securedAxiosInstance.put(`/backoffice/plan_sales/${planSaleId}`, {
      plan_sale: {
        status: "cancelled",
        cancellation_reason: cancellationReason,
        feedback_message: feedbackMessage,
      },
    });
  }

  updatePlanSale(planSaleId, planSaleInformations) {
    return securedAxiosInstance.put(`/backoffice/plan_sales/${planSaleId}`, {
      plan_sale: planSaleInformations,
    });
  }

  getPlanSaleCancellationReasons() {
    return securedAxiosInstance.get("/backoffice/cancellation_reasons");
  }

  getPlanSaleBillingInvoice(planSaleId, planSaleBillingId) {
    return securedAxiosInstance.get(`/backoffice/plan_sales/${planSaleId}/plan_sale_billings/${planSaleBillingId}`);
  }

  getSellers() {
    return securedAxiosInstance.get("/backoffice/sellers");
  }

  updateSeller(seller) {
    const { id, ...sellerData } = seller;
    return securedAxiosInstance.put(`/backoffice/sellers/${id}`, {
      seller: toSnakeCase(sellerData),
    });
  }

  deleteSeller(sellerId) {
    return securedAxiosInstance.delete(`/backoffice/sellers/${sellerId}`);
  }

  getInvoices(page, filtering_data) {
    return securedAxiosInstance.post(`/backoffice/invoices?page=${page}`, filtering_data);
  }

  getInsurers() {
    return securedAxiosInstance.get(`/backoffice/insurers`);
  }

  markInvoiceAsPaid(invoice) {
    return securedAxiosInstance.put(`/backoffice/invoices/paid?invoiceId=${invoice.id}`);
  }

  configureStore(storeData) {
    return securedAxiosInstance.post("/backoffice/stores/configure", toSnakeCase(storeData));
  }

  updateStoreConfigurationStatus(storeId, step, status) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}/update_configuration_status`, { step, status });
  }

  updateClaimStatus(params) {
    return securedAxiosInstance.put(`/backoffice/claims/status`, toSnakeCase(params));
  }

  uploadClaimInvoice(claim, costItems, invoiceFile) {
    let formData = new FormData();
    formData.append("invoice_file", invoiceFile);

    costItems
      .map((item) => toSnakeCase(item))
      .forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`cost_items[${index}][${key}]`, item[key]);
        });
      });

    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/invoices`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  impossibleQuotationUpload(claim, message, reason) {
    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/quotations`, {
      message,
      reason,
    });
  }

  uploadQuotation(claim, costItems, quotationFile, productState) {
    let formData = new FormData();
    formData.append("quotation_file", quotationFile);

    costItems
      .map((item) => toSnakeCase(item))
      .forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`cost_items[${index}][${key}]`, item[key]);
        });
      });

    formData.append("product_state", productState);

    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/quotations`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getRenewPlanSaleLink(planSaleId) {
    return securedAxiosInstance.get(`/backoffice/plan_sales/${planSaleId}/renewal_link`);
  }

  renewPlanSale(planSaleId) {
    return securedAxiosInstance.put(`/backoffice/plan_sales/${planSaleId}/renewal`);
  }

  sendInvoicesReport({ email, startDate, endDate }) {
    return securedAxiosInstance.get("/backoffice/invoices/reporting", {
      params: {
        email: email,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getUsers({ storeId, organizationId }) {
    return securedAxiosInstance.get(`/backoffice/users`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  getUser({ userId, storeId, organizationId }) {
    return securedAxiosInstance.get(`/backoffice/users/${userId}`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  updateUser({ userId, storeId, organizationId }, data) {
    return securedAxiosInstance.put(`/backoffice/users/${userId}`, {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: data.role,
        store_ids: data.store_ids,
      },
      store_id: storeId,
      organization_id: organizationId,
    });
  }

  deleteUser({ userId, storeId, organizationId }) {
    return securedAxiosInstance.delete(`/backoffice/users/${userId}`, {
      params: {
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  newUserFields({ storeId, organizationId }, data) {
    return securedAxiosInstance.get(`/backoffice/users/new`, {
      params: {
        profile_type: data.profile_type,
        store_id: storeId,
        organization_id: organizationId,
      },
    });
  }

  createUser({ storeId, organizationId }, data) {
    return securedAxiosInstance.post(`/backoffice/users`, {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: data.role,
        store_ids: data.store_ids,
      },
      store_id: storeId,
      organization_id: organizationId,
      profile_type: data.profile_type,
    });
  }

  getClaimDeductiblePaymentUrl(claimId) {
    return securedAxiosInstance.get(`backoffice/claims/${claimId}/deductible_payment_link`);
  }

  updateStoreStatus(storeId, status) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}/update_status`, { status });
  }

  updateStoreChallenge(storeId, challengeId) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}/update_challenge`, { challenge_id: challengeId });
  }

  updateStore(storeId, data) {
    return securedAxiosInstance.put(`/backoffice/stores/${storeId}`, {
      store: toSnakeCase(data),
    });
  }

  getBusinessVerticals(storeId) {
    return securedAxiosInstance.get(`/backoffice/stores/${storeId}/business_verticals`);
  }

  newBusinessVertical(storeId) {
    return securedAxiosInstance.get(`/backoffice/stores/${storeId}/business_verticals/new`);
  }

  createBusinessVertical(storeId, data) {
    return securedAxiosInstance.post(`/backoffice/stores/${storeId}/business_verticals`, {
      business_vertical: toSnakeCase(data),
    });
  }

  sendConfirmationEmail(planSaleId) {
    return securedAxiosInstance.post(`/backoffice/plan_sales/${planSaleId}/send_confirmation_email`);
  }

  updateMatchedContract(id, data) {
    return securedAxiosInstance.put(`/backoffice/matched_contracts/${id}`, data);
  }

  getInsurerReportings(page, filtering_data) {
    return securedAxiosInstance.get("/backoffice/insurer_reportings", {
      params: { page, ...filtering_data },
    });
  }

  updateInsurerReporting(id, data) {
    return securedAxiosInstance.put(`/backoffice/insurer_reportings/${id}`, toSnakeCase(data));
  }

  downloadQontoBulkTransferCSV(payload) {
    return securedAxiosInstance
      .post("/backoffice/insurer_reportings/qonto_bulk_transfer.csv", payload, { responseType: "blob" })
      .then((response) => {
        if (response.status === 204) errorNotification("Aucun transfert à effectuer sur les périodes sélectionnées");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qonto_bulk_transfer.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  }

  getGeneralAnalytics({ startDate, endDate, stores, organizationId }) {
    return securedAxiosInstance.get("/backoffice/analytics", {
      params: {
        start_date: startDate,
        end_date: endDate,
        stores,
        organization_id: organizationId,
      },
    });
  }

  getOnboardingData(params) {
    return securedAxiosInstance.get(`/backoffice/stores/onboardings`, {
      params: toSnakeCase(params),
    });
  }

  getSellersRanking(params) {
    return securedAxiosInstance.get("/sellers/ranking", {
      params: toSnakeCase(params),
    });
  }
}

export default new BackofficeService();
