<template>
  <div>
    <Popover
      as="div"
      id="desktop-menu"
      class="relative z-10 inline-block text-left"
    >
      <div>
        <PopoverButton
          class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          <span>{{ $t(cleanFilterName) }}</span>
          <span
            v-if="selectedOptions.length > 0"
            class="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums"
          >
            {{ selectedOptions.length }}
          </span>
          <ChevronDownIcon
            class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </PopoverButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <PopoverPanel
          class="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none z-10 max-h-56 overflow-y-scroll"
        >
          <div
            class="rounded-md shadow-sm mb-2 w-88"
            v-if="availableOptions.length > 8"
          >
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
              >
                <SearchIcon class="h-3 w-3 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                :name="`search-mobile-filter-${filterIdx}`"
                :id="`search-mobile-filter-${filterIdx}`"
                class="focus:ring-primary focus:border-primary block w-full rounded-md pl-10 sm:hidden border-gray-300"
                :placeholder="$t(`Search ${filterName.toLowerCase()}`)"
                @input="debounceSearch"
                :value="searchKeyword"
              />
              <input
                type="text"
                :name="`search-desktop-filter-${filterIdx}`"
                :id="`search-desktop-filter-${filterIdx}`"
                class="hidden focus:ring-primary focus:border-primary min-w-full rounded-md pl-6 sm:block sm:text-xs border-gray-300 py-1"
                :placeholder="$t(`Search ${filterName.toLowerCase()}`)"
                @input="debounceSearch"
                :value="searchKeyword"
              />
            </div>
          </div>
          <div
            v-for="(option, optionIdx) in filteredOptions"
            :key="option"
            class="flex items-center w-88"
          >
            <input
              :id="`filter-${filterIdx}-${optionIdx}`"
              type="checkbox"
              class="h-4 w-4 border-gray-300 rounded text-primary-dark focus:ring-primary"
              :value="option"
              v-model="selectedOptions"
            />
            <label
              :for="`filter-${filterIdx}-${optionIdx}`"
              class="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
            >
              {{ option ? $t(option) : null }}
            </label>
          </div>
          <div class="flex gap-2 items-center mt-2">
            <div
              class="text-center cursor-pointer inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              v-if="selectedOptions.length > 0"
              @click="clear"
            >
              {{ $t("Clear") }}
            </div>
            <div
              class="text-center cursor-pointer px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:bg-gray-50"
              v-if="
                enableSelectAll &&
                availableOptions.length !== selectedOptions.length
              "
              @click="selectAll"
            >
              {{ $t("Select All") }}
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { camelCaseToWords, capitalizeFirstLetter } from "../utils/string.js";

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    SearchIcon,
    ChevronDownIcon,
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterIdx: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: false,
    },
    availableFilters: {
      type: Array,
      required: true,
    },
    selectedFilters: {
      type: Array,
      required: true,
    },
    enableSelectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      openFilters: false,
      searchKeyword: "",
      debounce: null,
    };
  },

  computed: {
    selectedOptions: {
      get() {
        return this.selectedFilters[this.filterName];
      },

      set(options) {
        this.updateSelectedOptions(options);
      },
    },

    availableOptions() {
      return this.availableFilters[this.filterName];
    },

    filteredOptions() {
      if (this.searchKeyword === "") {
        return this.availableOptions;
      }

      return this.availableOptions.filter((option) => {
        return option?.toLowerCase().includes(this.searchKeyword.toLowerCase());
      });
    },

    cleanFilterName() {
      return capitalizeFirstLetter(camelCaseToWords(this.filterName));
    },
  },

  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchKeyword = event.target.value;
      }, 500);
    },

    clear() {
      this.updateSelectedOptions([]);
    },

    selectAll() {
      this.updateSelectedOptions(this.availableOptions);
    },

    updateSelectedOptions(newValue) {
      this.$emit("updateFilterSearch", this.filterName, newValue);
    },
  },
};
</script>
