<template>
  <div v-if="store">
    <StoreGeneralInformation
      :general-informations="store.generalInformations"
      :sales-field-managers="store.salesFieldManagers"
      @update:selectedSalesFieldManager="onSalesFieldManagerChange"
      @validateStoreConfig="validateStoreConfig"
      @storeUpdated="getStore"
    />
    <StoreConfigurationGeneralInformations
      class="mt-10"
      :store="store"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationGeneralInformations"
      @reject="rejectConfigurationGeneralInformations"
      @updated="refreshStore"
    />
    <StoreConfigurationContactInformations
      class="mt-10"
      :store="store"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationContactInformations"
      @reject="rejectConfigurationContactInformations"
      @updated="refreshStore"
    />
    <StoreConfigurationAfterSalesService
      class="mt-10"
      :store="store"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationAfterSalesService"
      @reject="rejectConfigurationAfterSalesService"
      @updated="refreshStore"
    />
    <StoreBillingInformation class="mt-10" :store="store" @remunerationModelUpdated="getStore" />
    <StoreDiscountInformation
      class="mt-10"
      :discount="store.discount"
      :challenges="store.challenges"
      :store-id="store.generalInformations.id"
      @discountUpdated="getStore"
      @challengeUpdated="getStore"
    />
    <StoreBusinessVerticals class="mt-10" :store="store" />
    <StoreMatchedContracts
      class="mt-10"
      :matched-contracts="store.matchedContracts"
      @matched-contract-update="getStore"
    />
    <StoreUsersInformation class="mt-10" :users="store.users" :store-id="store.generalInformations.id" />
    <SellersInformation class="mt-10" :sellers="store.sellers" @getSellers="getSellers" @deleteSeller="deleteSeller" />
    <StoreTechnicalConfiguration class="mt-10" :store="store" @storeUpdated="getStore" />
    <StoreApiKeysInformation class="mt-10" :api-keys="store.apiKeys" />
  </div>
</template>

<script>
import StoreGeneralInformation from "@/views/stores/details/StoreGeneralInformation.vue";
import StoreBusinessVerticals from "@/views/stores/details/StoreBusinessVerticals.vue";
import StoreMatchedContracts from "@/views/stores/details/StoreMatchedContracts.vue";
import StoreDiscountInformation from "@/views/stores/details/StoreDiscountInformation.vue";
import StoreBillingInformation from "@/views/stores/details/StoreBillingInformation.vue";
import SellersInformation from "@/views/stores/details/SellersInformation.vue";
import StoreTechnicalConfiguration from "@/views/stores/details/StoreTechnicalConfiguration.vue";
import StoreApiKeysInformation from "@/views/stores/details/StoreApiKeysInformation.vue";
import StoreUsersInformation from "@/views/stores/details/StoreUsersInformation.vue";
import StoreConfigurationGeneralInformations from "@/views/stores/details/StoreConfigurationGeneralInformations.vue";
import StoreConfigurationContactInformations from "@/views/stores/details/StoreConfigurationContactInformations.vue";
import StoreConfigurationAfterSalesService from "@/views/stores/details/StoreConfigurationAfterSalesService.vue";

import BackofficeService from "@/api/services/backoffice.js";

import { mapActions } from "vuex";

export default {
  components: {
    StoreApiKeysInformation,
    StoreTechnicalConfiguration,
    SellersInformation,
    StoreUsersInformation,
    StoreBillingInformation,
    StoreDiscountInformation,
    StoreMatchedContracts,
    StoreBusinessVerticals,
    StoreGeneralInformation,
    StoreConfigurationGeneralInformations,
    StoreConfigurationContactInformations,
    StoreConfigurationAfterSalesService,
  },

  data() {
    return {
      store: null,
    };
  },

  async beforeMount() {
    await this.getStore();
  },

  computed: {
    isStoreSetup() {
      return this.store?.generalInformations?.status === "setup";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getStore() {
      const storeId = this.$route.params.id;
      if (storeId) {
        const response = await BackofficeService.getStore(storeId);
        this.store = response.data;
      }
    },

    async onSalesFieldManagerChange(salesFieldManagerId) {
      const storeId = this.$route.params.id;
      if (storeId) {
        await BackofficeService.updateStoreSalesFieldManager(storeId, salesFieldManagerId);
      }
    },

    getSellers() {
      this.getStore();
    },

    async deleteSeller(sellerId) {
      const { success } = await BackofficeService.deleteSeller(sellerId);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Vendeur supprimé",
      });
      await this.refreshStore(); // sellers has changed
    },

    validateConfigurationGeneralInformations() {
      this.updateStoreConfigurationStatus("general_informations", "validated");
    },

    rejectConfigurationGeneralInformations() {
      this.updateStoreConfigurationStatus("general_informations", "rejected");
    },

    validateConfigurationContactInformations() {
      this.updateStoreConfigurationStatus("contact_informations", "validated");
    },

    rejectConfigurationContactInformations() {
      this.updateStoreConfigurationStatus("contact_informations", "rejected");
    },

    validateConfigurationAfterSalesService() {
      this.updateStoreConfigurationStatus("after_sales_service", "validated");
    },

    rejectConfigurationAfterSalesService() {
      this.updateStoreConfigurationStatus("after_sales_service", "rejected");
    },

    async validateStoreConfig() {
      const storeId = this.$route.params.id;
      const { success } = await BackofficeService.updateStoreStatus(storeId, "active");
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      await this.refreshStore(); //status has changed
    },

    async updateStoreConfigurationStatus(step, status) {
      const storeId = this.$route.params.id;
      const { success } = await BackofficeService.updateStoreConfigurationStatus(storeId, step, status);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      await this.refreshStore(); //status has changed
    },

    async refreshStore() {
      await this.getStore();
    },
  },
};
</script>
