<template>
  <Modal :open="open" @close="closeModal">
    <div>
      <div>
        <label for="claim_status" class="block text-sm font-medium leading-6 text-gray-900">Statut</label>
        <select
          id="claim_status"
          name="claim_status"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
          v-model="selectedStatus"
        >
          <option disabled key="default" value="">Choisir une option</option>
          <option v-for="status in claim.possibleNextClaimStatuses" :key="status">
            {{ status }}
          </option>
        </select>
      </div>

      <div class="mt-5">
        <label for="handling_type" class="block text-sm font-medium leading-6 text-gray-900">
          Type de prise en charge
        </label>
        <select
          id="handling_type"
          name="handling_type"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
          v-model="selectedHandlingType"
        >
          <option disabled key="default" value="">Choisir une option</option>
          <option v-for="handlingType in claim.possibleHandlingTypes" :key="handlingType.value" :value="handlingType">
            {{ handlingType.description }}
          </option>
        </select>
      </div>

      <div v-if="resolvedSelectedStatus" class="mt-5">
        <label for="refunded_entity" class="block text-sm font-medium leading-6 text-gray-900">
          Personne remboursée
          <span class="text-red-600">*</span>
        </label>
        <select
          id="refunded_entity"
          name="refunded_entity"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
          v-model="refundedEntity"
        >
          <option disabled key="default" value="">Choisir une option</option>
          <option
            v-for="refundedEntity in claim.possibleRefundedEntities"
            :key="refundedEntity"
            :value="refundedEntity"
          >
            {{ refundedEntity }}
          </option>
        </select>
      </div>

      <div v-if="resolvedSelectedStatus" class="mt-5">
        <label for="refunded_entity" class="block text-sm font-medium leading-6 text-gray-900">
          Montant de la prise en charge de ce sinistre
          <span class="text-red-600">*</span>
        </label>
        <div class="relative mt-2">
          <input
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
            id="refunded_amount"
            v-model="refundedAmount"
            type="number"
            name="refunded_amount"
            step=".01"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 sm:text-sm">€</span>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Estaly Manager Notes</p>
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            v-model="estalyManagerNotes"
          />
        </div>
      </div>
      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Claim Manager Notes</p>
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            v-model="claimManagerNotes"
          />
        </div>
      </div>
      <NotificationForm
        :name="'customer'"
        :selected-status="selectedStatus"
        :predefined-actions="claim.predefinedClientActions"
        :claim="claim"
        v-on:update="updateClientNotificationData"
      />
      <NotificationForm
        :name="'merchant'"
        :selected-status="selectedStatus"
        :predefined-actions="claim.predefinedMerchantActions"
        :claim="claim"
        v-on:update="updateMerchantNotificationData"
        @updateClaimManager="
          (newValue) => {
            selectedClaimManager = newValue;
          }
        "
      />
      <div class="flex justify-center">
        <GenericButton class="mt-5" @onClick="update">Mettre à jour</GenericButton>
      </div>
    </div>
  </Modal>
</template>
<script>
import { Modal } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import NotificationForm from "@/components/claim/details/status/NotificationForm.vue";
import { mapActions } from "vuex";

export default {
  props: ["claim", "open"],
  components: {
    NotificationForm,
    GenericButton,
    Modal,
  },

  computed: {
    resolvedSelectedStatus() {
      return this.selectedStatus === "resolved";
    },
  },

  watch: {
    selectedStatus() {
      this.clearData();
    },
    open() {
      this.selectedStatus = this.claim.currentStatus;
      this.selectedHandlingType = this.claim.handlingType;
      this.refundedEntity = this.claim.refundedEntity;
      this.refundedAmount = parseFloat(this.claim.refundedAmount ?? this.claim.invoice?.price);
    },
  },

  data: () => {
    return {
      clientNotificationData: null,
      merchantNotificationData: null,
      estalyManagerNotes: null,
      claimManagerNotes: null,
      selectedStatus: null,
      selectedClaimManager: null,
      selectedHandlingType: null,
      refundedEntity: null,
      refundedAmount: null,
    };
  },

  methods: {
    ...mapActions("claims", ["updateClaimStatus"]),
    ...mapActions("notifications", ["notify"]),

    updateClientNotificationData(newData) {
      this.clientNotificationData = newData;
    },
    updateMerchantNotificationData(newData) {
      this.merchantNotificationData = newData;
    },
    async update() {
      const { success } = await this.updateClaimStatus({
        claimId: this.claim.friendlyId,
        status: this.selectedStatus,
        statusParams: {
          estalyManagerNotes: this.estalyManagerNotes,
          claimManagerNotes: this.claimManagerNotes,
          merchantNotification: this.merchantNotificationData,
          clientNotification: this.clientNotificationData,
          selectedClaimManager: this.selectedClaimManager,
          handlingType: this.selectedHandlingType?.value,
          refundedEntity: this.refundedEntity,
        },
      });
      if (success) this.closeModal();
    },
    clearData() {
      this.clientNotificationData = null;
      this.merchantNotificationData = null;
      this.estalyManagerNotes = null;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
