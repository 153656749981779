export function capitalizeFirstLetter(string) {
  return string !== null ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

export function camelCaseToWords(string) {
  return string
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, "$1")
    .toLowerCase();
}

export function humanize(str) {
  return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/^./, (char) => char.toUpperCase()) // Capitalize the first letter
      .toLowerCase()
      .replace(/^\w/, (char) => char.toUpperCase()); // Capitalize the first word
}
