<template>
  <div>
    <div class="flex justify-between items-center">
      <PageHeader title="Invoices" />
      <div>
        <GenericButton @click="openInvoicesDownloadModal = true">
          {{ $t("Download") }}
        </GenericButton>
        <InvoicesDownloadModal
          :open="openInvoicesDownloadModal"
          @close="openInvoicesDownloadModal = false"
          @sendInvoicesReport="sendInvoicesReport"
        />
      </div>
    </div>
    <InvoicesSummary :bill-amount="billAmount" :statement-amount="statementAmount" />
    <div class="flex w-full gap-10 items-center">
      <MonthlyDateFilterSelector
        class="w-1/4"
        placeholder="Sélectionner une période"
        @newDateSelected="updateDateFilters"
      />
      <SearchBarFilter
        v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
        :key="filterIndex"
        :filterName="filtersName"
        :filterIdx="filterIndex"
        :current-page="currentPage"
        :available-filters="filters"
        :selected-filters="currentFilteringData"
        @updateFilterSearch="updateCurrentFilteringData"
      />
    </div>
    <div>
      <div>
        <div>
          <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                        <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Date Début</th>
                        <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Date Fin</th>
                        <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Id</th>
                        <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Montant</th>
                        <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Entité</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Statut paiement
                        </th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Télécharger
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                      <tr v-for="invoice in invoices" :key="invoice.month">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ $t(invoice.transactionType) }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ invoice.startDate }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ invoice.endDate }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ invoice.customId }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ formatPrice(invoice.amount) }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {{ invoice.salesChannel }}
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          <InvoicePaymentStatus :invoice="invoice" @invoicePaid="retrieveInvoices" />
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          <div class="flex">
                            <CloudDownloadIcon class="h-6 w-6 text-primary-dark cursor-pointer" aria-hidden="true" />
                            <a
                              :href="invoice.fileUrl"
                              class="pl-2 text-base font-semibold text-primary-dark hover:text-primary-dark"
                              target="_blank"
                            >
                              {{ $t("Download") }}
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    :currentPage="currentPage"
                    :totalPages="totalPages"
                    :totalCount="totalCount"
                    @changePage="handleChangePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import BackofficeService from "@/api/services/backoffice";
import InvoicePaymentStatus from "@/views/invoices/InvoicePaymentStatus.vue";
import { mapActions, mapState } from "vuex";
import { CloudDownloadIcon } from "@heroicons/vue/outline";
import InvoicesSummary from "@/views/invoices/InvoicesSummary.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import SearchBarFilter from "@estaly/ui/src/components/SearchBarFilter.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import InvoicesDownloadModal from "@/views/invoices/InvoicesDownloadModal";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    Pagination,
    PageHeader,
    SearchBarFilter,
    MonthlyDateFilterSelector,
    GenericButton,
    InvoicesSummary,
    InvoicePaymentStatus,
    CloudDownloadIcon,
    InvoicesDownloadModal,
  },

  computed: {
    ...mapState("backoffice", ["selectedOrganizationId"]),

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },
  },

  data() {
    return {
      invoices: [],
      filters: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
      },
      billAmount: null,
      statementAmount: null,
      totalNumberOfInvoices: 0,
      currentPage: 1,
      currentFilteringData: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
        start_date: null,
        end_date: null,
      },
      totalCount: null,
      totalPages: null,
      exportingInvoices: false,
      openInvoicesDownloadModal: false,
    };
  },

  mounted() {
    this.retrieveInvoices();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveInvoices();
    },

    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["start_date"] = startDate;
      this.currentFilteringData["end_date"] = endDate;
      this.handleChangePage(1);
    },

    async retrieveInvoices() {
      const response = await BackofficeService.getInvoices(this.currentPage, this.currentFilteringData);
      if (!response?.success) return;

      this.invoices = response.data.invoices;
      this.filters = response.data.filters;
      this.billAmount = response.data.billAmount;
      this.statementAmount = response.data.statementAmount;
      this.totalCount = response.data.page.totalCount;
      this.totalPages = response.data.page.totalPages;
    },

    async sendInvoicesReport(email) {
      this.openInvoicesDownloadModal = false;
      const filters = {
        email: email,
        startDate: this.currentFilteringData["start_date"],
        endDate: this.currentFilteringData["end_date"],
      };

      const { success } = await BackofficeService.sendInvoicesReport(filters);
      if (success) {
        this.notify({
          category: "simple",
          type: "success",
          title: "Generating the report, you should receive the e-mail in a few minutes.",
        });
      }
    },
  },
};
</script>
