<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <Table :columns="tableColumns" :data="formattedData" :is-deletable="true" @delete="deleteChallenge">
      <template #participants="{ row }">
        <div class="relative w-40 truncate cursor-pointer text-gray-600" @click="openParticipantsModal(row)">
          <span>{{ row.participants }}</span>
        </div>
      </template>
      <template #reporting="{ row }">
        <button @click="openReportingModal(row)" class="text-blue-600 hover:text-blue-800">
          <DownloadIcon class="cursor-pointerw-5 h-5" />
        </button>
      </template>
    </Table>

    <Modal :open="isParticipantsModalOpen" @close="closeParticipantsModal">
      <div class="p-2">
        <h2 class="text-lg font-semibold mb-4">Liste des Participants</h2>
        <ul>
          <li class="text-sm" v-for="(participant, index) in selectedParticipants" :key="index">
            {{ participant }}
          </li>
        </ul>
      </div>
    </Modal>

    <Modal :open="isReportingModalOpen" @close="closeReportingModal">
      <div class="p-2">
        <h2 class="text-lg font-semibold mb-4">Reporting</h2>

        <div class="max-h-60 overflow-y-auto border border-gray-200 rounded p-2">
          <div v-for="(store, index) in challengeStores" :key="store.id" class="flex items-center w-88 mt-2">
            <Checkbox
              :id="`store-${index}`"
              :name="`store-${index}`"
              :checkedValue="store.id"
              :uncheckedValue="false"
              v-model="selectedStoreIds"
              :label="store.salesChannel"
              labelClass="pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
            />
          </div>
        </div>

        <div class="flex gap-2 items-center mt-2">
          <div
            class="text-center cursor-pointer inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            @click="clearSelection"
          >
            Effacer
          </div>

          <div
            class="text-center cursor-pointer px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:bg-gray-50"
            @click="selectAllStores"
          >
            Tout sélectionner
          </div>
        </div>

        <GenericButton class="mt-5" @click="downloadChallengeReporting">Télécharger</GenericButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Table, Modal, Checkbox } from "@estaly/ui";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import BackofficeService from "@/api/services/backoffice";
import { isPresent } from "@/utils/validation";
import GenericButton from "@/components/utils/GenericButton.vue";
import { DownloadIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Table,
    Modal,
    Checkbox,
    GenericButton,
    DownloadIcon,
  },

  props: {
    challenges: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isParticipantsModalOpen: false,
      selectedParticipants: [],
      isReportingModalOpen: false,
      selectedChallengeId: null,
      challengeStores: [],
      selectedStoreIds: [],
    };
  },

  computed: {
    tableColumns() {
      return [
        { field: "id", label: "ID", numeric: true, bold: true },
        { field: "title", label: "Titre" },
        { field: "startDate", label: "Début" },
        { field: "endDate", label: "Fin" },
        { field: "salesTargets", label: "Objectifs de vente", price: true },
        { field: "participants", label: "Participants", slot: "participants" },
        { field: "reporting", label: "", slot: "reporting" },
      ];
    },
    formattedData() {
      return this.challenges.map((challenge) => ({
        id: challenge.id,
        title: challenge.title,
        startDate: challenge.startDate,
        endDate: challenge.endDate,
        salesTargets: isPresent(challenge.salesTargets)
          ? this.formatSalesTargets(challenge.salesTargets)
          : this.formatPointsPerEuro(challenge.pointsPerEuro),
        participants: this.getFormattedOrganizations(challenge),
      }));
    },
    storeOptions() {
      return this.challengeStores.map((store) => ({
        value: store.id,
        label: store.salesChannel,
      }));
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    formatStartEndDates(challenge) {
      return `${challenge.startDate}${window.innerWidth < 768 ? " au " + challenge.endDate : ""}`;
    },

    formatSalesTargets(salesTargets) {
      return salesTargets
        .map((item) => `• Target: ${item.target} | Reward: ${this.formatPrice(item.reward)}`)
        .join("<br/>");
    },

    formatPointsPerEuro(pointsPerEuro) {
      return `1€ = ${pointsPerEuro} points`;
    },

    getFormattedOrganizations(challenge) {
      return challenge.organizations.map((org) => org.name).join(" - ");
    },

    async deleteChallenge(challenge) {
      if (!confirm(`Supprimer le challenge ${challenge.title} (${challenge.id}) ?`)) return;

      const { success } = await BackofficeService.deleteChallenge(challenge.id);
      if (!success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Challenge supprimé",
      });
      this.$emit("refreshChallenges");
    },

    openParticipantsModal(row) {
      this.selectedParticipants = row.participants.split(" - ");
      this.isParticipantsModalOpen = true;
    },

    closeParticipantsModal() {
      this.isParticipantsModalOpen = false;
    },

    openReportingModal(challenge) {
      this.selectedChallengeId = challenge.id;
      const selectedChallenge = this.challenges.find((c) => c.id === challenge.id);
      this.challengeStores = selectedChallenge.stores;
      this.selectAllStores();
      this.isReportingModalOpen = true;
    },

    closeReportingModal() {
      this.isReportingModalOpen = false;
    },

    selectAllStores() {
      this.selectedStoreIds = this.challengeStores.map((store) => store.id);
    },

    clearSelection() {
      this.selectedStoreIds = [];
    },

    async downloadChallengeReporting() {
      const response = await BackofficeService.downloadChallengeReporting(
        this.selectedChallengeId,
        this.selectedStoreIds,
      );

      const url = window.URL.createObjectURL(new Blob([response.data.csv_data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.filename);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
