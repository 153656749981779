<template>
  <Modal :open="openModal" @closeUpdateModal="closeModal" @close="closeModal">
    <h1 class="text-center font-extrabold text-xl">
      {{ $t(creatingMode ? "Creating a user" : "Editing a user") }}
    </h1>
    <div class="grid grid-cols-2 gap-y-7 mt-7">
      <div>
        <label for="role" class="block text-sm text-gray-700">Role</label>
        <Listbox as="div" class="cursor-pointer" v-model="role">
          <div class="relative">
            <ListboxButton
              class="relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:max-w-xs"
            >
              {{ $t("roles." + role) }}
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                id="role"
                class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:max-w-xs"
              >
                <ListboxOption
                  v-for="availableRole in availableRoles"
                  :key="'availableRole-' + availableRole"
                  :value="availableRole"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    ]"
                  >
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                      {{ $t("roles." + availableRole) }}
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-primary-dark',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <div v-if="!isOrganizationManager">
        <label for="storeIds" class="text-sm text-gray-700 flex gap-3">
          <span>{{ $t("Stores") }}</span>
          <span v-if="!isSeller" class="underline text-sm text-gray-700 cursor-pointer" @click="selectAllStores">
            {{ $t("Select all") }}
          </span>
          <span v-if="!isSeller" class="underline text-sm text-gray-700 cursor-pointer" @click="unselectAllStores">
            {{ $t("Unselect all") }}
          </span>
        </label>
        <Listbox as="div" class="cursor-pointer" v-model="storeIds" :multiple="!isSeller">
          <div class="relative">
            <ListboxButton
              class="relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm min-h-9 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:max-w-xs"
            >
              <span class="block truncate">
                {{ selectedStoreNames }}
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                id="storeIds"
                class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:max-w-xs"
              >
                <ListboxOption
                  v-for="store in availableStores"
                  :key="'availableStoreIds-' + store.id"
                  :value="store.id"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    ]"
                  >
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                      {{ store.sales_channel }}
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-primary-dark',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <span v-if="storeIds.length === 0" class="text-red-700 text-sm">
          {{ $t("Select at least one store in the list") }}
        </span>
      </div>
      <div>
        <label for="firstName" class="block text-sm text-gray-700">
          {{ $t("First Name") }}
        </label>
        <Field
          id="firstName"
          name="firstName"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
          placeholder="Prénom"
          v-model="firstName"
        />
      </div>
      <div>
        <label for="lastName" class="block text-sm text-gray-700">
          {{ $t("Last Name") }}
        </label>
        <Field
          id="lastName"
          name="lastName"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
          placeholder="Nom de famille"
          v-model="lastName"
        />
      </div>
      <div>
        <label for="email" class="block text-sm text-gray-700">
          {{ $t("Email address") }}
        </label>
        <Field
          id="email"
          name="email"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
          placeholder="Email"
          v-model="email"
        />
      </div>
    </div>
    <div class="text-center mt-7 mb-5">
      <GenericButton @click="submitModal" :disabled="storeIds.length === 0">
        {{ $t(creatingMode ? "Create the user" : "Save the modifications") }}
      </GenericButton>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import { Field } from "vee-validate";
import GenericButton from "@/components/utils/GenericButton.vue";
import BackofficeService from "@/api/services/backoffice";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";

export default {
  components: {
    CheckIcon,
    ChevronDownIcon,
    ListboxOptions,
    ListboxOption,
    ListboxButton,
    Listbox,
    GenericButton,
    Field,
    Modal,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    editedUserId: {
      type: Number,
      required: true,
    },
    storeId: {
      type: Number,
      required: false,
    },
    organizationId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      role: null,
      availableRoles: [],
      storeIds: [],
      availableStores: [],
      creatingMode: null,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getUserData() {
      const response = await BackofficeService.getUser({
        userId: this.editedUserId,
        storeId: this.storeId,
        organizationId: this.organizationId,
      });
      this.firstName = response.data.user.first_name;
      this.lastName = response.data.user.last_name;
      this.email = response.data.user.email;
      this.availableRoles = response.data.user.available_roles;
      this.role = response.data.user.role;
      this.availableStores = response.data.user.available_stores;
      this.storeIds = response.data.user.store_ids;
    },

    async getUserFields() {
      const response = await BackofficeService.newUserFields(
        { storeId: this.storeId, organizationId: this.organizationId },
        { profile_type: "OrganizationManager" },
      );
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.availableRoles = response.data.user.available_roles;
      this.role = this.availableRoles[0];
      this.availableStores = response.data.user.available_stores;
      this.storeIds = [this.storeId];
    },

    closeModal() {
      this.$emit("closeModal");
    },

    async submitModal() {
      let response;
      if (this.creatingMode) {
        response = await this.createUser();
      } else {
        response = await this.saveUserModifications();
      }
      if (!response?.success) return;

      this.$emit("userListUpdated");
      this.notify({
        category: "simple",
        type: "success",
        title: "User saved successfully",
      });
    },

    createUser() {
      return BackofficeService.createUser(
        { storeId: this.storeId, organizationId: this.organizationId },
        {
          ...this.editedUserData,
          profile_type: "StoreManager",
        },
      );
    },

    saveUserModifications() {
      return BackofficeService.updateUser(
        {
          userId: this.editedUserId,
          storeId: this.storeId,
          organizationId: this.organizationId,
        },
        this.editedUserData,
      );
    },

    selectAllStores() {
      this.storeIds = this.availableStores.map((store) => store.id);
    },

    unselectAllStores() {
      this.storeIds = [];
    },
  },

  computed: {
    editedUserData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        role: this.role,
        store_ids: this.storeIds,
      };
    },

    selectedStoreNames() {
      const selectedStoreIds = [this.storeIds].flat();
      const store_names = this.availableStores
        .filter((item) => selectedStoreIds.includes(item.id))
        .map((e) => e.sales_channel);

      return store_names.join(", ");
    },

    isOrganizationManager() {
      return this.role === "organizationmanager";
    },

    isSeller() {
      return this.role === "seller";
    },
  },

  watch: {
    role() {
      if (this.isSeller && this.storeIds.length > 1) {
        this.storeIds = this.storeIds[0];
      } else if (!Array.isArray(this.storeIds)) {
        this.storeIds = this.storeIds ? [this.storeIds] : [];
      }
    },

    openModal(opened) {
      if (!opened) return;

      if (this.editedUserId) {
        this.creatingMode = false;
        this.getUserData();
      } else {
        this.creatingMode = true;
        this.getUserFields();
      }
    },
  },
};
</script>

<style scoped>
.min-h-9 {
  min-height: 38px;
}
</style>
